import React, { useState, useEffect, useCallback } from 'react'
import Cookies from 'js-cookie'
import TransitionLink from 'gatsby-plugin-transition-link'

import { css } from '@emotion/core'
import tw from 'twin.macro'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { outTransition, outDuration } from '../transitions/fade'

import {
  inTransition,
  inDuration,
} from '../transitions/homepage-to-project-transition'

const cookieName = 'sbs-cookie'

const CookieNotification = () => {
  const [isShown, setIsShown] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (Cookies.get(cookieName) !== 'yes') {
        setIsShown(true)
      }
    }, 4000)
  }, [])

  const closeNotification = useCallback(() => {
    setIsShown(false)
    Cookies.set(cookieName, 'yes', { expires: 7 })
  }, [])

  return (
    <div
      tw="font-sans py-2 md:py-1 bg-brand-green text-white fixed inset-x-0 bottom-0 text-sm md:text-xxs w-full z-50 transform transition-transform duration-100"
      css={css`
        ${isShown ? tw`translate-y-0!` : tw`translate-y-full!`}
      `}
    >
      <div tw="container flex justify-between">
        <div tw="flex flex-row items-center">
          <div>
            This site uses cookies. To find out more, read our{' '}
            <TransitionLink
              tw="underline hover:no-underline focus:no-underline"
              exit={{ trigger: outTransition, length: outDuration }}
              entry={{
                trigger: inTransition,
                length: inDuration,
                delay: outDuration,
              }}
              to="/privacy-policy"
            >
              privacy policy
            </TransitionLink>
            .
          </div>
        </div>
        <div tw="flex flex-row items-center">
          <button
            tw="cursor-pointer text-lg md:text-base outline-none!"
            onClick={closeNotification}
          >
            <FontAwesomeIcon icon={['fal', 'times']} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default CookieNotification
