import gsap from 'gsap'

export const outDuration = 0.4
export const inDuration = 0.4

export const outTransition = ({ node }) => {
  const tl = gsap.timeline()
  tl.fromTo(node, { autoAlpha: 1 }, { autoAlpha: 0, duration: outDuration })
  return tl
}

export const inTransition = ({ node }) => {
  const tl = gsap.timeline()
  tl.fromTo(node, { autoAlpha: 0 }, { autoAlpha: 1, duration: inDuration })
  return tl
}
