import React from 'react'
import PropTypes from 'prop-types'

import { Global, css } from '@emotion/core'
import tw from 'twin.macro'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faYoutube,
  faPinterest,
  faFlickr,
  faTumblr,
  faVimeo,
} from '@fortawesome/free-brands-svg-icons'
import {
  faCircle,
  faEnvelope,
  faPhone,
  faPlay,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faTimes,
  faGripHorizontal,
  faListUl,
  faArrowRight,
} from '@fortawesome/pro-light-svg-icons'

import CookieNotification from '../components/cookie-notification'

library.add(
  faCircle,
  faTimes,
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faYoutube,
  faPinterest,
  faFlickr,
  faTumblr,
  faVimeo,
  faEnvelope,
  faPhone,
  faPlay,
  faGripHorizontal,
  faListUl,
  faArrowRight
)

const Layout = ({ children }) => {
  return (
    <>
      <Global
        styles={css`
          html {
            ${tw`font-sans font-light text-black`}
            font-size: 16px;

            @media (min-width: 768px) {
              font-size: 20px;
            }

            @media (min-width: 1024px) {
              font-size: 22px;
            }

            @media (min-width: 1520px) {
              font-size: 24px;
            }
          }
          html,
          body {
            transition: background-color 1s linear;
            ${tw`bg-light-grey`};
          }
          html {
            visibility: hidden;
          }

          html.wf-active,
          html.wf-inactive {
            visibility: visible;
          }
          .no-scroll {
            ${tw`fixed w-full h-full`};
          }
          .no-scroll-not-mobile {
            ${tw`md:fixed md:w-full md:h-full`};
          }
          p {
            ${tw`mb-4`}

            &:last-child {
              ${tw`mb-0`}
            }
          }
        `}
      />
      <main tw="font-sans min-h-screen flex flex-col relative z-10">
        <div tw="relative flex-grow">{children}</div>
      </main>
      <CookieNotification />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

Layout.defaultProps = {
  children: null,
}

export default Layout
