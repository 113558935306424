import gsap from 'gsap'

import { isEdgeLegacy } from '../utilities/browser-checks'

export const outDuration = 3
export const inDuration = 2

export const outTransition = ({ node }) => {
  const titleEl = node.querySelector('[data-gsap="project-title"]')
  const canvasEl = node.querySelector('canvas')
  const topUiEl = node.querySelector('[data-gsap="top-ui"]')

  const tl = gsap.timeline()
  tl.set(node, { zIndex: 100 })
  tl.set(node, { overflow: 'hidden' })
  tl.set(titleEl, { autoAlpha: 0, y: 30 })
  tl.add('start')
  tl.to(topUiEl, { y: -topUiEl.clientHeight, duration: 1 }, 'start')
  tl.to(titleEl, { autoAlpha: 1, y: 0, duration: 1 }, 'start+=0.5')
  tl.to(canvasEl, {
    autoAlpha: 0,
    y: -window.innerHeight / 2,
    ease: 'power2.in',
    duration: 1,
  })
  tl.to(
    titleEl,
    {
      autoAlpha: 0,
      y: -window.innerHeight / 2,
      ease: 'power2.in',
      duration: 1,
    },
    '-=0.5'
  )
  return tl
}

export const inTransition = ({ node }) => {
  // this can be removed after Edge Legacy is gone
  if (!isEdgeLegacy()) {
    const siteHeadEl = node.querySelector('[data-gsap="site-head"]')
    const pageContentEl = node.querySelector('[data-gsap="page-content"]')

    gsap.set([siteHeadEl, pageContentEl], { opacity: 0 })

    const siteHeadStartPosY = -(
      getVerticalPositionOfElement(siteHeadEl) + siteHeadEl.clientHeight
    )

    const tl = gsap.timeline()
    tl.set(node, { overflow: 'hidden' })
    tl.set(siteHeadEl, { opacity: 1, y: siteHeadStartPosY })
    tl.set(pageContentEl, { opacity: 1, y: window.innerHeight })
    tl.add('start')
    tl.to(
      pageContentEl,
      { y: 0, duration: 0.75, ease: 'power3.out' },
      'start+=0.25'
    )
    tl.add(() => {
      // this allows us to use position fixed again within the element
      pageContentEl.style.transform = null
    })
    tl.to(siteHeadEl, { y: 0, duration: 0.5, ease: 'power3.out' }, 'start+=0.5')
    return tl
  } else {
    const tl = gsap.timeline()
    tl.fromTo(node, { autoAlpha: 0 }, { autoAlpha: 1, duration: inDuration })
    return tl
  }
}

const getVerticalPositionOfElement = el => {
  return el.getBoundingClientRect().top + window.scrollY
}
